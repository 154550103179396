body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#img-logo > img {
    width: 150px;
    height:26px;
}
/* 
body {
  background-image: url("") !important;
}     */

.ra-field {
  display: inline-block !important;
  width: 30% !important;  
}


/* .ra-field:last-child {  
  width: 100% !important;  
} */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} 

input[type=number] { -moz-appearance:textfield; }input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { 
  -moz-appearance:textfield; 
  text-align: right;
} 

th > span{
  text-align: center;
}

th{
  text-align: center !IMPORTANT;
}


th:first-child {
  text-align: left !IMPORTANT;
}


.table-seg-Flex{ box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); margin-top: 20px; }
.table-seg-Flex > thead > th > td{ border: 1px solid rgba(224, 224, 224, 1);}   
.table-seg-Flex > thead > th{ border: 1px solid rgba(224, 224, 224, 1); padding: 0}   
.table-seg-Flex > tbody > td { border: 1px solid rgba(224, 224, 224, 1); }
.table-seg-Flex > tbody > tr > td { border: 1px solid rgba(224, 224, 224, 1); }
.table-seg-Flex > tbody > th > td { border: 1px solid rgba(224, 224, 224, 1); }
.table-seg-Flex > tbody > tr > th { border: 1px solid rgba(224, 224, 224, 1); padding: 0}

.Longitudes_segmentos > div > input {text-align: right;}

.labelcostos > div > input {text-align: right;}

.labelNumber > div > input {text-align: right;}


.validaciones{
  width: 100% !important;
}

.validaciones > div.grid12{
  display: flex !important;
  width: 100% !important;
}

.validaciones > div.grid6{
  display: flex !important;
  width: 50% !important;
}

.validaciones > div.grid4{
  display: flex !important;
  width: 33% !important;
}

.validaciones > div.grid3{
  display: flex !important;
  width: 23% !important;
}

.cancelar{
  background-color: #e87070;
  color: white;
  padding: 8px ;
  position: relative;
  border-radius: 3px;
  height: 20px ;
  width: 120px;
  padding-right: 0px;
  margin-left: 15px;
  text-decoration: none;
  display: flex;
}

.align-number > div > input{ 
  
  text-align: right;

  } 


/* CSS LOGIN */
  
.container-login{
  width: 100%;
  height: 100vh;
  display: flex;
}

.container-login > div:nth-child(1){
  width: 60%;
  height: 100%;
  background-color: black;
}

.container-login > div:nth-child(2){
  width: 40%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.container-login > div:nth-child(2) > div:nth-child(1){
  width: 100%;
  height: 25%;
  display: flex;
  align-items: flex-end;
  font-family: sans-serif;
  color: #787877;
}


.container-login > div:nth-child(2) > div:nth-child(2){
  width: 100%;
  height: 60%;  
}


.container-login > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) {
  width: 100%;
  height: 30%;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > a:nth-child(1) {
  width: 100%;
  height: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-login{
  width: 63%;
  height: 70%;
}

.tittle-login{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tittle-login > div{
  width: 100%;
  display: flex;
  justify-content: center;
}

.input-group{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.input-group > input.user{
  background: url('usuario.png') no-repeat scroll 9px 3px;
  padding-left:35px;
  height: 25px;
  border-radius: 20px;
  width: 60%;
  margin-bottom: 10px;
}

.input-group > input.pass{
  background: url('bloquear.png') no-repeat scroll 9px 3px;
  padding-left:35px;
  height: 25px;
  border-radius: 20px;
  width: 60%;
  margin-bottom: 10px;
}

.input-group > input:focus{
  outline: none;
}

.input-group > label{ width: 66% }

.input-group > button{
  width: 57%;
  height: 45px;
  text-align: center;
  border: 0;
  border-radius: 17px;
  margin-top: 40px;
  background-color: #3092f5;
  color: white;
  font-size: 21px;
  cursor: pointer;
 }
 
 .input-group > button:focus{
  outline: none;
}

 .input-group > button:hover{ box-shadow: 1px 2px 5px #000b56; } 

 .ContenedorGaleria > div:nth-child(2){
   background-color: black;
   width: 50%;
 }

 .GaleriaShow{
   height: 100%;
 }

 .Reload{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reload2{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reload2 > img{
  width: 10%;
  margin: 10px;
}

.estilos_array > ul > div > li >section{
  display: flex;
  vertical-align: top;  
  
}


.estilos_array > ul > div > li >section > div{
  width: 50%;
  margin-left : 15px; 
}

.estilos_array > ul > div > li > p{
  display: none;
}
.estilos_array_puentes > ul > div > li > p{
  display: none;
}

/*ESTILOS DE CALIFICACIÓN*/

.estilos_array-c > ul > div > li >section{
  display: flex;
}

.estilos_array-c > ul > div > li >section > div{
  margin-left: 30px;
  margin-top: 0PX;
}

.estilos_array-c > ul > div > li >section > div >div{
  width : 400px;
}
/* .estilos_array-c {
  
  border: #dddddd solid 1px !IMPORTANT;
  padding: 10px !IMPORTANT;
} */


/* .estilos_array > ul > div > li >section > div >div{
  margin-top: 0px;
} */

.estilos_array_enum > ul > div > li > p{
  display: none;
}



.loader-municipios{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-municipios > img {
  width: 40%;
}

.input-group > img{
  display: none;
 }

/* .footer-login{  
  /* width: 40%;
  height: 41%;   */
  /* position: absolute;
  bottom: 0; */
  /* background: url(/static/media/Flogin.d33394d1.png) no-repeat scroll -7px -345px; */
  /* background: url('Flogin.png') no-repeat scroll; 

} */

@media (max-height: 800px) and (orientation: landscape) {
  .container-login > div:nth-child(2) > div:nth-child(2) > div:nth-child(2){
    margin-top: -18px;
  }

  .container-login > div:nth-child(2) > div:nth-child(1){
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    font-family: sans-serif;
    color: #787877;
  }

  .content-msj-error-login > div:last-child{
    width: 50% !important;
    height: auto !important;
    background-color: white;  
    border-radius: 15px;  
    position: absolute;
    padding: 15px;
    text-align: center;
  }

  .content-msj-error-login #btn-close-msj-error{
    width: 20%;
    text-align: center;
    border: 0;
    border-radius: 17px;
    background-color: #3092f5;
    color: white;
    font-size: 21px;
    cursor: pointer;
    height: 60%;
    margin-top: 5px;
  }

  .input-group > button{
    width: 50%;
    margin-top: 33px;
    position: relative;
    z-index: 1;
  }

  #imgLoginFooter{
    position: relative;
    z-index: 0;
  }
  
  .content-msj-error-login{
    z-index: 2;
  }

  #modal-error-login{
    position: absolute;
    z-index: 2;
  }
   
}

@media (max-width: 600px) and (orientation: portrait) {
  .container-login {
    flex-direction: column;
    width: 90% !important;
  }

  .container-login #form-login-responsive {
    width: 100%;
    order: 1;
  }
  .container-login #gallery-responsive {
    width: 100%;
    order: 2;
  }

  .content-msj-error-login > div:last-child{
    width: 50% !important;
    height: auto !important;
    background-color: white;  
    border-radius: 15px;  
    position: absolute;
    padding: 15px;
    text-align: center;
  }

  .content-msj-error-login #btn-close-msj-error{
    width: 50% !important;
    text-align: center;
    border: 0;
    border-radius: 17px;
    background-color: #3092f5;
    color: white;
    font-size: 21px;
    cursor: pointer;
    height: 60%;
    margin-top: 5px;
  }

  .input-group > button{
    position: relative;
    z-index: 1;
  }

  #imgLoginFooter{
    position: relative;
    z-index: 0;
  }
  
  .content-msj-error-login{
    z-index: 2;
  }

  #modal-error-login{
    position: absolute;
    z-index: 2;
  }

  #img-logo {
    width:35% !important
  }

  #img-logo > img {
    width: 113px !important;
    height:20px !important;
  }

  #AppBarId > div {
    width: 99% !important;
  }

  #root {
    width: 120% !important;
  }

  #projectSelectedAppBar{
    width: 25%;
  }

  #react-admin-title{
    display: none;
  }

  #AppBarId button {
    width: 25px !important;
  }
   
}

.content-msj-error-list{  
  text-align: center; 
  opacity: 0.5; 
  font-family: Roboto, sans-serif; 
  padding-top: 2%; 
}

.inventariosSegmentos > ul > div > li > p{
  display: none;
}

.content-msj-error-login{  
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;  
}

#content-msj{
  /* display: none; */
  position: absolute;
  width: 100%;
  height: 100%;  
}

.content-msj-error-login > div:first-child{
  width: 100%;
  height: 100%;
  background-color: black;  
  opacity: 0.4;
}

.content-msj-error-login > div:last-child{
  width: 40%;
  height: 15%;
  background-color: white;  
  border-radius: 15px;  
  position: absolute;
  padding: 15px;
  text-align: center;
}

#btn-close-msj-error{
  width: 20%;
  text-align: center;
  border: 0;
  border-radius: 17px;
  background-color: #3092f5;
  color: white;
  font-size: 21px;
  cursor: pointer;
  height: 30%;
  margin-top: 5px;
}

#btn-close-msj-error:hover{ border: 1px solid #000b56; } 
.mostrar-coordenadas{
  margin-top: -40px;
  width: 100px;
}

.titulo-mostrar-coordenadas{
  display: inline-flex !important;
  margin-top: 40px;
  width: 100px;
}

.Evaluaciones-tabs{ width: 100% !important; margin-top: 15px; }
.Evaluaciones-tabs > div:nth-child(4) { display: none; }

.estilos_array_evaluacion > ul > div >  li > p { display: none; }
.estilos_array_evaluacion > ul > div >  li{
  width: 20%;
  border: none;
  display: inline-block;
}

.border-form-none > div > div::before{
  border-bottom: 0 !important;
}

.border-form-none > div > div > input{
  color: black;
  font-weight: bold;
}
.estilos_dimension > ul > div > li > p { display : none; }
.estilos_dimension > ul > div > li > section > div { display: inline-block; width: 21%; margin: 0 18px; text-align: right;}
.estilos_dimension > ul > div > li > section > div:nth-child(1) { width: 100%; margin: 0;}
.estilos_dimension > ul > div > li > section > div:nth-child(2) { margin-left: 0;}

.Listar_telefono {text-align: right}


/* ESTILOS DEL MAPA
.gm-style-iw-c {
  /* background-color: #000b56 !IMPORTANT; 
  height: 415px !IMPORTANT;
  max-height: 415px  !IMPORTANT;
}

.gm-style-iw-d{
  height: 425px !IMPORTANT;
  max-height: 425px  !IMPORTANT;
} */

/* .array_input_edit > ul > div > li >section{
  display: flex;
  vertical-align: top;  
  
} */

.array_input_edit > ul > div > li > section > div{
  display: inline-block;
}


/* MODELO DE COSTOS PERS */
.disabled_none{
  color : black;
}

#canceledButton{
  background: rgb(227 108 108) !important;
}

#successfullButton{
  background: #3f51b5 !important;
}

/* CAROUSEL CARGA MASIVA */
.carousel-container{
  width: 100% !important;
  height: 100vh;
  display: flex;
}

.carousel-slider-class{
  width: 60% !important;
}

.carousel-item-forms{
  width: 60% !important;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}